<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text"><b>Berita</b> website Klinik Hayandra</div>
      <b-button
        squared
        variant="success"
        @click="btnAddOnClick()"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <Table purpose="master" />
          </template>
        </Card>
      </div>
    </div>

    <b-card no-body class="overflow-hidden">
      <b-row no-gutters>
        <b-col md="12">
          <Form :form="form" :route="'website-posts'" v-if="FormDetailActv == false && FormEditActv == false"/>
          <FormEdit :form="form" :route="'website-posts/' + form.id" v-if="FormEditActv == true"/>
          <FormDetail :form="form" :route="'website-posts/' + form.id" v-if="FormDetailActv == true"/>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import Form from "@/component/websites/PostForm.vue";
import FormEdit from "@/component/websites/PostFormEdit.vue";
import FormDetail from "@/component/websites/PostFormDetail.vue";
import Table from "@/component/websites/PostTable.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "website-post",
  data() {
    return {
      form: {
        title: "",
        excerpt: "",
        content: "",
        date: new Date().toISOString().substr(0, 10),
        author: "",
        tags: "",
        post_category_id: '',
        image: "",
        status: "",
        yt_link:"",
      },
      FormEditActv: false,
      FormDetailActv: false,
    };
  },
  components: {
    Card,
    Table,
    Form,
    FormEdit,
    FormDetail,
  },
  methods:{
    resetFormOnNew(){
      // reset form on new (tambah data)
      var self = this;
      Object.keys(this.form).forEach(function (key, index) {
        if (key === "image") {
          self.form[key] = [];
        } else if(key === "_method"){
          self.form[key] = "post"
        } else {
          self.form[key] = "";
        }
      });
    },

    scrollToElement(options) {
      let el = this.$el.getElementsByClassName("pagination")[0];
      el = el ?? this.$el.getElementsByClassName("table-setting")[0];
      console.log("el", el)
      if (el) {
        el.scrollIntoView(options);
        // reset form on new (tambah data)
        this.resetFormOnNew()
      }
    },

    scrollToTop(options) {
      // window.scrollTo(0, 0);
       let el = this.$el.getElementsByClassName("alert")[0];
       let er = this.$el.getElementsByClassName("alert");
      // el = el ?? this.$el.getElementsByClassName("table-setting")[0];
      console.log("el", el)
      console.log("er", er)
      if (el) {
        el.scrollIntoView(options);
        // reset form on new (tambah data)
        this.resetFormOnNew()
      }
    },

    btnAddOnClick(){
      this.scrollToElement({ behavior: 'smooth' })
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Website", route: "" },
      { title: "CMS", route: "" },
      { title: "Post", route: "" },
    ]);

    // Scroll ToTop
    this.$root.$on("scrollToTop", () => {
      // reset form on new (tambah data)
      this.resetFormOnNew()
    });

    // Add Website Post
    this.$root.$on("updateWebsitePost", () => {
      // reset form on new (tambah data)
      this.scrollToTop({ behavior: 'smooth' })
      this.resetFormOnNew()
    });

      // Cancel Add Website Post
    this.$root.$on("cancelAddWebsitePost", () => {
      // reset form on new (tambah data)
      this.scrollToTop({ behavior: "smooth" });
      this.resetFormOnNew();
    });

    //  Edit Website Post
    this.$root.$on("getWebsitePostById", (data) => {
      this.FormDetailActv = false
      this.FormEditActv = true
     this.scrollToElement({ behavior: 'smooth' })
      console.log("data", data)

      if(data.status == 0){
        data.status = false
      }else if(data.status == 1){
        data.status = true
      }

     this.form = data
     console.log("form", this.form)
     this.form['_method'] = 'put'
    });

    // Cancel Edit
    this.$root.$on("cancelWebsitePostUpdate", () => {
      this.FormEditActv = false
      this.scrollToTop({ behavior: 'smooth' })
      this.resetFormOnNew()
    });

    // Detail Web Post
    this.$root.$on("getWebsitePostByIdDetail", (data) => {
      this.FormDetailActv = true
      this.FormEditActv = false
     this.scrollToElement({ behavior: 'smooth' })
      console.log("data", data)

      if(data.status == 0){
        data.status = false
      }else if(data.status == 1){
        data.status = true
      }
     this.form = data
    });
  },
};
</script>
